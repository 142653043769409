import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "react-input-range/lib/css/index.css"
import useOutsideClick from "../../../hooks/useOutsideClick"
import { handleLogin, isLoggedIn, getUser } from "../../../services/auth"
import { EqualHeight, EqualHeightElement } from 'react-equal-height/clean'
import "react-equal-height/clean/main.css"
import Layout from "../../../templates/Page"
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import FilterResult from "../../ContentBuilder/FilterResult"
import MainDesignFilter from "../../../templates/Includes/MainDesignFilter"
import SidebarFilter from "../../../templates/Includes/SidebarFilter"
import SearchBar from "../../SearchBar"
import ContactUs from "../../ContactUs"
import Elements from "../../../templates/Elements/Elements"
import { connect } from "react-redux"
import { doFilter } from "../../../services/filter"
import { setCurrent } from "../../../state/nav"
import config from "../../../config/config"
import QuickViewModalHomeDesignData from "../../ContentBuilder/Modal/QuickViewModalHomeDesignData"
import RecentViews from "../../RecentViews"
import { resetFilter } from "../../../state/filter"
import { getBoardFromLocalStorage } from "../../../state/board"

const HomeDesignList = ({ activeFilters, currentBoards, sort, dispatch }) => {
  const [show, setShow] = useState(false)
  const [items, setItems] = useState([])
  const ref = useRef()
  useOutsideClick(ref, () => {
    setShow(false)
  })
  useEffect(() => {
    dispatch(setCurrent('home-designs'))
    dispatch(getBoardFromLocalStorage())
  }, [])
  const resetFilterHandler = useCallback(() => dispatch(resetFilter()), [])

  const grid = useMemo(() => <AllDesigns activeFilters={activeFilters} currentBoards={currentBoards} sort={sort} setItems={data => setItems(data)} resetFilter={resetFilterHandler} />, [activeFilters, currentBoards, sort])
  
  return (
    <Layout>
      <div className="container">
        <MainDesignFilter />

        <div className="wrap-section no-margin">
          <div className="explore-function-content">
            <FilterResult />
          </div>
        </div>

        <div className="wrap-section row no-gutters py-5">
          <div className="col-lg-3">
            <SidebarFilter items={items} />
          </div>
          {grid}
        </div>
        <RecentViews />
      </div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  activeFilters: state.filter.active,
  currentBoards: state.board.all,
  sort: state.sort.current
})

export default connect(mapStateToProps, null)(HomeDesignList)

function AllDesigns({ activeFilters, currentBoards, sort, setItems, resetFilter }) {
  const loadPage = () => {
    pageLimit = pageLimit + 6
    setPageLimit(pageLimit)
    if (pageLimit >= _total_items) {
      setHasMore(false)
    }
  }
  var [pageLimit, setPageLimit] = useState(6)
  var [hasMore, setHasMore] = useState(true)

  const allItems = useStaticQuery(graphql`
    {
        silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
          hidePricingGlobally
        }
      }
      allSilverStripeDataObject(filter: {className: {eq: "Undigital__Objects__HomeDesign"}, UndigitalHomeDesign: {status: {eq: "Active"}}}, sort: {fields: UndigitalHomeDesign___objectID, order: DESC}) {
        nodes {
          UndigitalHomeDesign {
            status
            name
            urlSegment
            description
            objectID            
            imagePath            
            imagePosition
            tileBlackTitle
            Floorplans {
              UndigitalFloorplan {
                objectID
                name
                homeSize
                homeArea
                rrpPrice            
                lotWidth
                lotLength
                status
                numOfLayout
                bed
                bath
                garage
                living
                firstFloorImagePath
                groundFloorImagePath
                GroundFloorRoomsAmenities {
                  UndigitalRoomAmenity {
                    name
                    objectID
                  }
                }
                Level1RoomsAmenities {
                  UndigitalRoomAmenity {
                    name
                    objectID
                  }
                }
              }
            }
            homeTypeID
            storeysID
            FeaturedFloorPlan {
              UndigitalFloorplan {
                objectID
                name
                homeSize
                homeArea
                rrpPrice            
                lotWidth
                lotLength
                bath
                bed
                garage
                living                
                firstFloorImagePath
                groundFloorImagePath
              }
            }
            HomeType {
              UndigitalHomeType {
                name
              }
            }
            Storeys {
              UndigitalStoreys {
                name
              }
            }
            Designers {
              UndigitalDesigner {
                name
              }
            }
            Disclaimer {
              UndigitalTextAsset {
                title
              }
            }
            OnDisplays {
              UndigitalDisplay {
                name
              }
            }
          }
        }
      }
    }
  `)
  const host = config.host
  const globalConfig = allItems.silverStripeDataObject.UndigitalGlobalConfig
  const url = typeof window !== "undefined" ? window.location.pathname : ""
  var checkURL = url.substring(0, url.length - 1)
  const segment = checkURL.substring(checkURL.lastIndexOf("/") + 1)
  const search = typeof window !== "undefined" ? window.location.search : "/"
  const params = new URLSearchParams(search)
  // var sortBy = params.get("sort")
  var keyword = params.get("keyword")
  const unfiltered_homedesigns = allItems.allSilverStripeDataObject.nodes
  
  let _allData = []
  var i = 0  
  for (; unfiltered_homedesigns[i];) {
    var _homedesign = unfiltered_homedesigns[i].UndigitalHomeDesign
    _homedesign.link = `/explore/home-designs/${_homedesign.urlSegment}`
    _homedesign.className = 'HomeDesign';
    var designName = _homedesign.name

    var _onDisplays = ""
    var onDisplaysArr = []
    var onDisplayList = _homedesign.OnDisplays
    if (onDisplayList !== null) {
      onDisplayList.forEach(function (item, index) {
        onDisplaysArr.push(item.UndigitalDisplay.name)
      })
      _onDisplays = onDisplaysArr.join(", ")
    }

    var _designers = ""
    var designersArr = []
    var designersList = _homedesign.Designers
    if (designersList !== null) {
      designersList.forEach(function (item, index) {
        designersArr.push(item.UndigitalDesigner.name)
      })
      _designers = designersArr.join(", ")
    }
    _homedesign.designersName = _designers

    var _disclaimer = ""
    var disclaimerArr = []
    var disclaimerList = _homedesign.Disclaimer
    if (disclaimerList !== null) {
      disclaimerList.forEach(function (item, index) {
        disclaimerArr.push(item.UndigitalTextAsset.title)
      })
      _disclaimer = disclaimerArr.join(", ")
    }
        var floorplans_liked = 0
        
        if(_homedesign.Floorplans){
            for (const floorPlan of _homedesign.Floorplans) {
                var cmsFloorPlan = floorPlan.UndigitalFloorplan
                if (cmsFloorPlan.status !== 'Active') continue
                var isSaved = false
                for (const board of currentBoards) {
                    const items = JSON.parse(board.Items)
                    for (const key in items) {
                        if (key.includes(_homedesign.className) && items[key].ID == _homedesign.objectID && items[key].VariantID == cmsFloorPlan.objectID) {
                          isSaved = true
                        }
                    }                    
                }
                if(isSaved){
                    floorplans_liked += 1
                }
                var tile = {
                  _homedesign: { ..._homedesign },
                  ...cmsFloorPlan,
                  rrpPrice: parseFloat(Math.round(cmsFloorPlan.rrpPrice * 1000) / 1000).toFixed(2)
                }          
                tile.formattedPrice = "$" + tile.rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                _homedesign.formattedPrice = tile.formattedPrice
                if(isSaved){
                    tile.isSaved = true
                    floorplans_liked += 1
                }
                tile.rrp = tile.formattedPrice
                tile.likes = floorplans_liked
                var isDoubleStorey = false;
                var match = true
                var _storeys = ""
                var storeysList = _homedesign.Storeys
                if (storeysList !== null) {
                  _storeys = storeysList.UndigitalStoreys.name;
                  if (_storeys === 'Double Storey') {
                    isDoubleStorey = true;
                  }
                }
                tile.isDoubleStorey = isDoubleStorey;

                if (keyword) {
                    keyword = keyword.toLowerCase()
                    let _designName = designName.toLowerCase()
                    _storeys = _storeys.toLowerCase()

                    let matched_title = _designName.search(keyword)
                    let matched_storeys = _storeys.search(keyword)
                    let matched_tileName = tile.name.search(keyword)

                    if (
                      matched_title === -1 &&
                      matched_storeys === -1 &&
                      matched_tileName === -1
                    ) {
                      match = false
                    }
                }

                tile.bed = []
                tile.bath = []
                tile.garage = []
                tile.living = []
                tile.roomAmenities = []
                tile.roomAmenitiesObjs = []
                var shortSize = ''                
                if(cmsFloorPlan.homeSize || cmsFloorPlan.homeArea){
                    shortSize += '<strong>SIZE</strong>'
                    if(cmsFloorPlan.homeSize){
                        shortSize += ' / ' + cmsFloorPlan.homeSize + ' SQ'
                    }
                    if(cmsFloorPlan.homeArea){
                        shortSize += ' / ' + cmsFloorPlan.homeArea + ' M2'
                    }
                }
                tile.shortSize = shortSize
                if (cmsFloorPlan.bed && !tile.bed.includes(cmsFloorPlan.bed)) tile.bed.push(cmsFloorPlan.bed)
                if (cmsFloorPlan.bath && !tile.bath.includes(cmsFloorPlan.bath)) tile.bath.push(cmsFloorPlan.bath)
                if (cmsFloorPlan.garage && !tile.garage.includes(cmsFloorPlan.garage)) tile.garage.push(cmsFloorPlan.garage)
                if (cmsFloorPlan.living && !tile.living.includes(cmsFloorPlan.living)) tile.living.push(cmsFloorPlan.living)

                if (cmsFloorPlan.GroundFloorRoomsAmenities) {
                  for (const ite of cmsFloorPlan.GroundFloorRoomsAmenities) {
                    const ra = ite.UndigitalRoomAmenity
                    if (!tile.roomAmenities.some(t => t === ra.objectID)) {
                      tile.roomAmenities.push(ra.objectID)
                      tile.roomAmenitiesObjs.push(ra)
                    }
                  }
                }

                if (cmsFloorPlan.Level1RoomsAmenities) {
                  for (const ite of cmsFloorPlan.Level1RoomsAmenities) {
                    const ra = ite.UndigitalRoomAmenity
                    if (!tile.roomAmenities.some(t => t === ra.objectID)) {
                      tile.roomAmenities.push(ra.objectID)
                      tile.roomAmenitiesObjs.push(ra)
                    }
                  }
                }

                if (_homedesign.homeTypeID) tile.homeTypes = [_homedesign.homeTypeID]
                if (_homedesign.storeysID) tile.storeys = [_homedesign.storeysID]
                tile.className = 'HomeDesign'          
                tile.link = `/explore/home-designs/${_homedesign.urlSegment}/?vid=${tile.objectID}`
                if (match) {
                  _allData.push(tile)
                }
            }
        }
    i++
  }  

  // deep copy the original tiles
  const _allTiles = JSON.parse(JSON.stringify(_allData))
  useEffect(() => {
    setItems(_allTiles)
  }, [activeFilters, currentBoards, sort])

  // do filter
  if (activeFilters.length) _allData = doFilter(activeFilters, _allData)

  _allData.sort((a, b) => {
    if (sort === "az") {
      return a.name > b.name ? 1 : -1
    } else if (sort === "za") {
      return a.name < b.name ? 1 : -1
    } else if (sort === "latest") {
      return Number(a.objectID) < Number(b.objectID) ? 1 : -1
    } else if (sort === "pricehightolow") {
      return a.rrpPrice < b.rrpPrice ? 1 : -1
    } else if (sort === "pricelowtohigh") {
      return a.rrpPrice > b.rrpPrice ? 1 : -1
    } else {
      return Number(a.objectID) < Number(b.objectID) ? 1 : -1
    }
  })

  const _total_items = _allData.length
  const currentData = _allData.slice(0, pageLimit)
  if (_total_items == currentData.length) {
    hasMore = false
  }
  const [objectClass, setObjectClass] = useState()
  const [objectID, setObjectID] = useState()
  const [variantID, setVariantID] = useState(null)  
  const [showQuickView, setShowQuickView] = useState(false)
  const [quickViewData, setQuickViewData] = useState(null)
  const setUpQuickViewModal = useCallback((e, item, vt) => {
    e.preventDefault()
    item.vt = vt
    setObjectClass(item.className)
    setQuickViewData(item)
    setShowQuickView(true)
    setObjectID(item.objectID)
    setVariantID(vt.objectID)
  }, [])  
  const [showAddBoard, setShowAddBoard] = useState(false)
  const [loadImage, setLoadImage] = useState(false)
  const setShowBoard = () => {
    if (isLoggedIn()) {
      setShowAddBoard(true)
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = "/login/"
      }
    }
  }
  if (typeof window !== 'undefined') {
    window.dispatchEvent(new Event('resize'))
  }
  return (
    <>
      <div className="col-lg-9 col-xl-8 offset-xl-1">
        {currentData.length ? (
          <div className="browser-home-wrap">
            <EqualHeight>
              <Row>
                {currentData.map((item, idx) => (
                  <Col md={6} key={`${idx}-${item.link}`}>
                    <EqualHeightElement name="equal-el" disable={typeof window === "undefined" || typeof document === "undefined"}>
                      <Link to={item.link} onClick={e => setUpQuickViewModal(e, item._homedesign, { ...item, _homedesign: undefined })} className="browser-home-cart">
                        <div className="browser-home-title-wrap">
                          <div className="browser-home-title-content">
                            <h4 className="browser-home-title mb-0">
                              {item._homedesign.name}
                            </h4>
                            {(item.bed || item.bath || item.living || item.garage) && (
                              <div className="browser-home-feature">
                                <div className="d-flex mx-n2">
                                  <div className="mx-2 item-feature">
                                    {item.bed}
                                    {item.bed && (
                                      <svg width="26" height="26">
                                        <use xlinkHref="/img/icons.svg#icon-bed-stroke"></use>
                                      </svg>
                                    )}
                                  </div>
                                  <div className="mx-2 item-feature">
                                    {item.bath}
                                    {item.bath && (
                                      <svg width="26" height="26">
                                        <use xlinkHref="/img/icons.svg#icon-bath-stroke"></use>
                                      </svg>
                                    )}
                                  </div>
                                  <div className="mx-2 item-feature">
                                    {item.living}
                                    {item.living && (
                                      <svg width="26" height="26">
                                        <use xlinkHref="/img/icons.svg#icon-sofa-stroke"></use>
                                      </svg>
                                    )}
                                  </div>
                                  <div className="mx-2 item-feature">
                                    {item.garage}
                                    {item.garage && (
                                      <svg width="26" height="26">
                                        <use xlinkHref="/img/icons.svg#icon-garages-stroke"></use>
                                      </svg>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {item.isSaved ? (
                              <div className="heart-counter">
                                  <span>{item.numOfLayout ? item.numOfLayout : ''}</span>
                              </div>
                          ):(
                              <>
                              {item.numOfLayout != 0 && (                        
                              <div className="circle-floor white">
                                  <span>{item.numOfLayout}</span>
                                  <small>Layout</small>
                              </div>
                              )}
                              </>
                          )}
                        </div>
                        <div className={`p-2 p-lg-3 text-center mt-auto${!item.groundFloorImagePath ? ' no-image' : ''}`}>
                          <div
                            className={`browser-home-img ${item._homedesign.imagePosition}`}
                          >
                            {item.isDoubleStorey ? (
                              <div className="browser-img-wrap">
                                {item.groundFloorImagePath && (
                                  <img
                                    src={item.groundFloorImagePath}
                                    alt={item.name}
                                  />
                                )}
                                {item.firstFloorImagePath && (
                                  <img
                                    src={item.firstFloorImagePath}
                                    alt={item.name}
                                  />
                                )}
                              </div>
                            ) : (
                              <div className="browser-img-wrap">
                                {item.groundFloorImagePath && (
                                  <img
                                    className="mw-100"
                                    src={item.groundFloorImagePath}
                                    alt={item.name}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="browser-home-bottom">
                          <div className="browser-home-bottom-border">
                            <hr/>
                            <img src="/img/account/birdy-logo.svg" />
                          </div>
                          {item.shortSize !== '' && (
                          <div className="browser-home-bottom-inner">
                              <span
                              dangerouslySetInnerHTML={{ __html: item.shortSize }}
                            />
                          </div>
                          )}
                        </div>
                      </Link>
                    </EqualHeightElement>
                  </Col>
                ))}
              </Row>
            </EqualHeight>
          </div>
        ) : (
          <p>Awh dam! Sorry, we can't find what you're looking for. Try a different search or <a href="#" onClick={() => resetFilter()}>reset</a> your filters to continue exploring Birdy.</p>
        )}

        {currentData.length > 0 && hasMore && (
          <div className="text-center">
            <button
              onClick={loadPage}
              className="btn btn-black btn-lg mt-5"
            >
              LOAD MORE
            </button>
          </div>
        )}
      </div>
      <CreateBoardModal
        show={showAddBoard}
        handleClose={() => setShowAddBoard(false)}
        objectClass={objectClass}
        variantID={variantID}
        objectID={objectID}
      />
      {quickViewData !== null && (
        <QuickViewModalHomeDesignData
          show={showQuickView}
          handleClose={() => setShowQuickView(false)}
          item={quickViewData}
          hidePricingGlobally={globalConfig.hidePricingGlobally}
          handleShowAddBoard={() => setShowAddBoard(true)}
        />
      )}
    </>
  )
}
